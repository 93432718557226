
import { Component, Vue } from "vue-property-decorator";
import Purenavbar from "@/components/Utility/Purenavbar.vue";

@Component({
  components: {
    Purenavbar,
  },
})
export default class PaymentLoading extends Vue {}
